import bg from "../assets/img/bg.jpg";
import androidLogo from "../assets/img/android-1.svg";
import windowsLogo from "../assets/img/windows-1.svg";
import ios from "../assets/img/ios-1.svg";
import Navigation from "./Navigation";
import { Link } from "react-router-dom";
const Header = () => {
  return (
    <header style={{ backgroundImage: `url(${bg})` }} className=" h-3/5">
      <div className="h-full" style={{ background: "rgba(0, 0, 0, 0.45)" }}>
        <Navigation />
        <div
          className="absolute flex flex-col items-center justify-center w-screen cursor-pointer h-2/5"
          style={{ direction: "ltr" }}
        >
          <h1 className="mt-16 text-xl antialiased font-black text-center text-white md:text-4xl">
            دانلود کتاب الکترونیک و کتاب صوتی با کتابخوان
          </h1>
          <Link to="/apps">
            <div className="flex items-center px-4 py-2 mt-6 bg-white rounded-full cursor-pointer hover:shadow-xl hover:opacity-95">

              <span className="text-xs antialiased font-semibold md:text-sm">
                نصب رایگان اپلیکیشن کتابخوان
              </span>
              <img className="h-6 ml-2 " src={androidLogo} alt="اپلیکیشن کتابخوان" />
              <img className="h-6 ml-1" src={ios} alt="اپلیکیشن کتابخوان آیفون" />
              <img className="h-6 ml-1 text-blue-400" src={windowsLogo} alt="اپلیکیشن کتابخوان ویندوز" />

            </div>
          </Link>
        </div>
      </div>
    </header>
  );
};

export default Header;

import Navigation from "../components/Navigation";
import Footer from "../components/Footer";
function PrivacyPolicy() {
  return (
    <>
      <Navigation />
      <main className="main-section">
        <div className="container py-5 antialiased focus-within">
          <h1 className="text-2xl font-black">سیاست حفظ حریم خصوصی</h1>
          <p className="mt-6">
            این سیاست حفظ حریم خصوصی برای اپلیکیشن کتابخوان (در ادامه به عنوان
            "اپلیکیشن" نامیده می‌شود) برای دستگاه‌های همراه که توسط میثم (در
            ادامه به عنوان "ارائه دهنده خدمات" نامیده می‌شود) به عنوان یک سرویس
            مبتنی بر تبلیغات ایجاد شده است، اعمال می‌شود. این سرویس برای استفاده
            "به همان شکلی که است" مقصود است.
          </p>

          <p className="mt-12 font-semibold">جمع‌آوری و استفاده از اطلاعات</p>
          <ul className="mx-6 leading-7 list-disc">
            <li>
              اپلیکیشن اطلاعات را هنگامی که آن را دانلود و استفاده می‌کنید
              جمع‌آوری :می‌کند. این اطلاعات ممکن است شامل اطلاعاتی مانند
            </li>
            <li>آدرس پروتکل اینترنتی دستگاه شما (برای مثال آدرس IP)</li>
            <li>
              صفحات اپلیکیشن که شما بازدید می‌کنید، زمان و تاریخ بازدید شما،
              زمانی که بر روی آن صفحات صرف می‌کنید
            </li>
            <li>زمانی که بر روی اپلیکیشن صرف می‌کنید</li>
            <li>سیستم عاملی که بر روی دستگاه همراه خود استفاده می‌کنید</li>
            <li>
              اپلیکیشن موقعیت مکانی دستگاه شما را جمع‌آوری می‌کند که به ارائه
              دهنده خدمات کمک می‌کند تا موقعیت جغرافیایی تقریبی شما را تعیین
              کرده و از آن به شکل‌های زیر استفاده کند:
            </li>
            <li>
              سرویس‌های موقعیت جغرافیایی: ارائه دهنده خدمات از داده‌های موقعیت
              برای ارائه ویژگی‌هایی مانند محتوای شخصی‌سازی، پیشنهادات مرتبط و
              خدمات مبتنی بر موقعیت استفاده می‌کند.
            </li>
            <li>
              تجزیه و تحلیل و بهبودات: داده‌های موقعیتی تجمیع شده و ناشناس به
              ارائه دهنده خدمات کمک می‌کند تا رفتار کاربران را تجزیه و تحلیل
              کرده، روندها را شناسایی کند و کارایی و عملکرد کلی اپلیکیشن را
              بهبود بخشد.
            </li>
            <li>
              سرویس‌های شخص ثالث: گاه به گاه، ارائه دهنده خدمات ممکن است
              داده‌های موقعیتی ناشناس را به خدمات خارجی ارسال کند. این خدمات به
              ارائه دهنده خدمات کمک می‌کنند تا اپلیکیشن را بهبود بخشند و
              پیشنهادات خود را بهینه کنند.
            </li>
            <li>
              ارائه دهنده خدمات ممکن است از اطلاعاتی که شما ارائه کرده‌اید برای
              تماس با شما در فواصل زمانی مختلف برای ارسال اطلاعات مهم،
              اطلاعیه‌های لازم و تبلیغات بازاریابی استفاده کند.
            </li>
            <li>
              برای تجربه بهتر در حین استفاده از اپلیکیشن، ارائه دهنده خدمات ممکن
              است از شما بخواهد که اطلاعات شناسایی شخصی خاصی را ارائه دهید، از
              جمله اما محدود به ایمیل، نام، تصویر در صورتی که توسط کاربر
              بارگذاری شود. اطلاعاتی که ارائه دهنده خدمات درخواست می‌کند توسط او
              نگهداری می‌شود و به عنوان در این سیاست حفظ حریم خصوصی شرح داده شده
              است، استفاده می‌شود.
            </li>
          </ul>

          <p className="mt-12 font-semibold">دسترسی شخص ثالث</p>
          <ul className="mx-6 leading-7 list-disc">
            <li>
              {" "}
              تنها داده‌های تجمیع شده و ناشناس به طور دوره‌ای به خدمات خارجی
              ارسال می‌شود تا به ارائه دهنده خدمات در بهبود اپلیکیشن و خدماتش
              کمک کند. ارائه دهنده خدمات ممکن است اطلاعات شما را با اشخاص ثالث
              به روش‌هایی که در این بیانیه حفظ حریم خصوصی توضیح داده شده است، به
              اشتراک بگذارد.
            </li>
            <li>
              لطفاً توجه داشته باشید که اپلیکیشن از خدمات شخص ثالثی استفاده
              می‌کند که سیاست حفظ حریم خصوصی مخصوص به خود را برای پردازش داده‌ها
              دارند. در زیر، لینک‌های به سیاست حفظ حریم خصوصی ارائه دهندگان
              خدمات شخص ثالث که توسط اپلیکیشن استفاده می‌شود، آمده است:
            </li>
            <li>
              <a
                href="https://www.google.com/policies/privacy/"
                target="_blank"
              >
                Google Play Services
              </a>
            </li>
            <li>
              <a
                href="https://firebase.google.com/support/privac/"
                target="_blank"
              >
                Google Analytics for Firebase
              </a>
            </li>
            <li>
              <a
                href="https://firebase.google.com/support/privac/"
                target="_blank"
              >
                Firebase Crashlytics
              </a>
            </li>
          </ul>
          <p>
            ارائه دهنده خدمات ممکن است اطلاعات ارائه شده توسط کاربر و اطلاعاتی
            که به صورت خودکار جمع‌آوری شده‌اند را منتشر کند:
          </p>
          <p>
            هنگامی که قانوناً لازم است، مانند برای رفع مقررات یا فرآیند قانونی
            مشابه؛ وقتی به خوبی اعتقاد دارند که افشای اطلاعات ضروری است برای حفظ
            حقوق خود، حفظ ایمنی شما یا ایمنی دیگران، تحقیق در مورد تقلب، یا پاسخ
            به درخواست دولت؛ با ارائه دهندگان خدمات قابل اعتماد که برای رسیدن به
            اهداف ما کار می‌کنند، استفاده می‌کنند، استفاده مستقلی از اطلاعاتی که
            ما به آن‌ها افشا می‌کنیم ندارند و به توافقات موجود در این بیانیه حفظ
            حریم خصوصی پایبند هستند.
          </p>
          <p className="mt-12 font-semibold">حقوق انصراف</p>
          <p className="mt-6">
            شما می‌توانید با حذف اپلیکیشن، به آسانی از جمع‌آوری همه اطلاعات توسط
            اپلیکیشن جلوگیری کنید. شما می‌توانید از فرآیندهای حذف استاندارد
            استفاده کنید که ممکن است به عنوان بخشی از دستگاه همراه شما یا از
            طریق بازار متروی اپلیکیشن موبایل یا شبکه موبایل در دسترس باشد.
          </p>
          <p className="mt-12 font-semibold">
            سیاست نگهداری اطلاعات
          </p>
          <p className="mt-6">
            ارائه دهنده خدمات داده‌های ارائه شده توسط کاربر را تا زمانی که شما
            از اپلیکیشن استفاده می‌کنید و برای مدت معقولی پس از آن نگهداری
            می‌کند. اگر می‌خواهید تا داده‌های ارائه شده توسط کاربر که از طریق
            اپلیکیشن ارائه شده‌اند را حذف کنید، لطفاً با ارائه دهنده خدمات تماس
            بگیرید تا آن‌ها در زمان معقولی پاسخ دهند.
          </p>
          <p className="mt-12 font-semibold">
            کودکان
          </p>
          <p className="mt-6">
            ارائه دهنده خدمات از اپلیکیشن برای به دست آوردن داده‌ها یا بازاریابی
            به کودکان زیر 13 سال به شیوه‌ای آگاهانه استفاده نمی‌کند.

          </p>
          <p className="mt-6">
            این اپلیکیشن هیچ گونه خدماتی را برای هیچ کسی زیر 13 سال ارائه
            نمی‌دهد. ارائه دهنده خدمات به طور آگاهانه اطلاعات شناسایی شخصی از
            کودکان زیر 13 سال را جمع‌آوری نمی‌کند. در صورتی که ارائه دهنده خدمات
            متوجه شود که یک کودک زیر 13 سال اطلاعات شخصی را ارائه داده است،
            ارائه دهنده خدمات بلافاصله این اطلاعات را از سرورهای خود حذف خواهد
            کرد. اگر شما والدین یا نگهبان هستید و آگاه هستید که فرزند شما
            اطلاعات شخصی به ما ارائه داده است، لطفاً با ارائه دهنده خدمات
            (hello@ketabkhaan.com) تماس بگیرید تا اقدامات لازم را انجام دهند.
          </p>
          <p className="mt-12 font-semibold">
            امنیت
          </p>
          <p className="mt-6">
            ارائه دهنده خدمات به حفظ محرمانگی اطلاعات شما اهمیت می‌دهد. ارائه
            دهنده خدمات تدابیر فیزیکی، الکترونیکی و رویه‌ای را فراهم می‌کند تا
            اطلاعاتی که ارائه دهنده خدمات پردازش و نگهداری می‌کند، محافظت شود.

          </p>
          <p className="mt-12 font-semibold">
            تغییرات
          </p>
          <p className="mt-6">
            این سیاست حفظ حریم خصوصی ممکن است به دلایلی از زمان به زمان به‌روز
            شود. ارائه دهنده خدمات شما را از هر تغییری در سیاست حفظ حریم خصوصی
            با به‌روزرسانی این صفحه با سیاست حفظ حریم خصوصی جدید مطلع خواهد کرد.
            به شما توصیه می‌شود که به طور منظم این سیاست حفظ حریم خصوصی را برای
            هر تغییر مشاهده کنید، زیرا استفاده مداوم به عنوان تایید همه تغییرات
            در نظر گرفته می‌شود.
          </p>
          <p className="mt-6">
            این سیاست حفظ حریم خصوصی از تاریخ 2024-05-01 اعمال می‌شود.
          </p>
          <p className="mt-6">
            با استفاده از اپلیکیشن، شما به پردازش اطلاعات خود به شکلی که در این
            سیاست حفظ حریم خصوصی تعیین شده است و یا به تغییراتی که ما اعمال
            می‌کنیم، رضایت می‌دهید.
          </p>
          <p className="mt-12 font-semibold">
            تماس با ما
          </p>
          <p className="mt-6">
            اگر هرگونه سوالی درباره حریم خصوصی در حال استفاده از اپلیکیشن دارید
            یا سوالی درباره روش‌ها دارید، لطفاً با ارائه دهنده خدمات از طریق
            ایمیل به{" "}
            <a href="mailto:hello@ketabkhaan.com" className="font-semibold">
              hello@ketabkhaan.com
            </a>{" "}
            تماس بگیرید.{" "}
          </p>
        </div>
      </main>
      <Footer />
    </>
  );
}

export default PrivacyPolicy;

import Navigation from "../components/Navigation";
import Footer from "../components/Footer";
function Contact() {
  return (
    <>
      <Navigation />
      <main className="w-11/12 h-full mx-auto my-10 leading-relaxed bg-white rounded-md lg:w-10/12">
        <div className="w-full py-5 antialiased focus-within">
          <section className="relative body-font">
            <div className="container px-5 py-12 mx-auto">
              <div className="flex flex-col w-full mb-12 text-center">
                <h1 className="text-2xl font-black">تماس و پشتیبانی</h1>
                <p className="mx-auto mt-4 text-base leading-relaxed lg:w-2/3">
                  جهت ارسال پیشنهادات، انتقادات ، حذف حساب و یا امور دیگر از
                  فورم ذیل استفاده نمایید.
                </p>
              </div>
              <div className="mx-auto lg:w-1/2 md:w-2/3">
                <form action="#" className="flex flex-wrap -m-2">
                  <div className="w-full p-2 md:w-1/2">
                    <div className="relative">
                      <label
                        for="name"
                        className="text-sm leading-7 text-gray-600 "
                      >
                        اسم
                      </label>
                      <input
                        type="text"
                        required
                        id="name"
                        name="name"
                        autofocus
                        className="input-style"
                      />
                    </div>
                  </div>
                  <div className="w-full p-2 md:w-1/2">
                    <div className="relative">
                      <label
                        for="email"
                        className="text-sm leading-7 text-gray-600 "
                      >
                        ایمیل
                      </label>
                      <input
                        type="email"
                        required
                        id="email"
                        name="email"
                        className="input-style"
                      />
                    </div>
                  </div>
                  <div className="w-full p-2">
                    <div className="relative">
                      <label
                        for="message"
                        className="text-sm leading-7 text-gray-600 "
                      >
                        پیام
                      </label>
                      <textarea
                        id="message"
                        required
                        name="message"
                        className="h-32 px-3 leading-8 resize-none input-style"
                      ></textarea>
                    </div>
                  </div>
                  <div className="w-full p-2">
                    <button className="flex px-8 py-2 mx-auto text-white border-0 rounded bg-primary hover:bg-indigo-500 focus:outline-none">
                      ثبت پیام
                    </button>
                  </div>
                  <div className="w-full p-2 mt-4 border-t border-gray-200">
                    <p className="text-sm">
                      در صورتیکه خواستار حذف شدن یکی از کتاب‌ها هستید به ایمیل
                      <a
                        className="mx-2 text-indigo-500"
                        href="mailto:copyright@ketabkhaan.com"
                      >
                        copyright@ketabkhaan.com
                      </a>
                      پیام بگذارید.
                    </p>
                    <p className="mt-6 text-sm">
                      <span className="font-bold">
                        توجه:{" "}
                      </span>
                      در صورت خواستار شدن حذف حساب ما تمام اطلاعات شما
                      را از جمله: نام و نام کاربری، ایمیل آدرس و تصویر تان را
                      در ظرف یک هفته حذف میکنیم.
                    </p>
                  </div>
                </form>
              </div>
            </div>
          </section>
        </div>
      </main>
      <Footer />
    </>
  );
}

export default Contact;

import Navigation from "../components/Navigation";
import Footer from "../components/Footer";
function TOS() {
  return (
    <>
      <Navigation />
      <main
        className="main-section"
      >
        <div className="container py-5 antialiased focus-within">
          <h1 className="text-2xl font-black">شرایط استفاده از خدمات</h1>
          <p className="mt-6">
            برای ثبت نام و استفاده از خدمات کتابخوان رعایت قوانین زیر الزامی
            است. کتابخوان این حق را برای خود محفوظ می‌دارد که هر زمان که لازم
            بود قوانین زیر را تغییر دهد یا به آن اضافه کند. هر زمان شما خلاف
            قوانین عمل کنید کتابخوان می‌تواند عضویت شما را برای مدتی یا برای
            همیشه تعلیق کند.{" "}
          </p>

          <p className="mt-12 font-semibold">کاربران عضو</p>
          <ul className="mx-6 leading-7 list-disc">
            <li>
              - همه می‌توانند از خدمات کتابخوان استفاده کنند. برای استفاده از
              خدمات مانند خواندن کتاب ثبت نام الزامی است.
            </li>
            <li>
              هر گونه فعالیتی که از طریق حساب کاربری صورت بگیرد به عهده کاربر
              است.
            </li>
            <li>
              تمام اطلاعات که شما برای عضو شدن در اختیار کتابخوان قرار می‌دهید بر
              طبق سیاست حریم شخصی، نزد سرویس محفوظ می‌ماند.
            </li>
          </ul>

          <p className="mt-12 font-semibold">نظرات</p>
          <p>نظراتی که شامل موارد زیر باشند، منتشر نخواهند شد:</p>
          <ul className="mx-6 leading-7 list-disc">
            <li>تبلیغات به هر شکلی</li>
            <li>شماره تلفن، ایمیل و اطلاعات تماس</li>
            <li>نام کاربری افراد در سایت‌های مختلف مانند تلگرام و ... </li>
            <li>الفاظ رکیک</li>
            <li>تبلیغ به نفع یا علیه نویسندگان و ناشران</li>
            <li>هر نوع محتوای متناقض عرف جامعه</li>
            <li>محتوای سیاسی و توهین به قومیت‌ها</li>
            <li>توهین به مقدسات، اقوام یا اشخاص </li>
            <li>هرگونه لینک به سایت‌های دیگر</li>
            <li>
              نظراتی که دارای محتوای جمع‌آوری رأی و لایک دیگر کاربران باشند
            </li>
            <li>درخواست و دعوت به چت و گفتگو</li>
            <li>نظراتی غیر‌مرتبط با کتاب</li>
          </ul>
        </div>
      </main>
      <Footer />
    </>
  );
}

export default TOS;

import React from "react";
import Navigation from "../components/Navigation";
import Footer from "../components/Footer";
import kImage from "../assets/img/ketaabkhan-home.png";
import DownloadLinks from "../components/DownloadLinks";

const Applications = () => {
  return (
    <>
      <Navigation />
      <main className="w-full" style={{ direction: "ltr" }}>
        <div className="w-full mx-auto lg:w-8/12 ">
          <div className="grid grid-cols-2 my-8 space-x-3 lg:ml-8">
            <div className="mt-2 text-right md:mt-0">
              <p className="text-2xl font-bold leading-loose md:leading-loose md:text-4xl">
                <span >صدها</span>
                <span className="px-2 text-primary">کتاب الکترونیک </span>
                <br />و<span className="pr-2 text-primary">کتاب صوتی</span>
                <br />
                در جیب شما
              </p>

              <div className="mt-8 md:mt-12 ">
                <ul style={{ direction: "rtl" }}>
                  <li className="my-2 text-sm md:my-6 md:text-xl">
                    <span className="text-primary">»</span> دارای بیش از ۳۰۰
                    کتاب رایگان
                  </li>
                  <li className="my-2 text-sm md:my-6 md:text-xl">
                    <span className="text-primary">»</span> دارای کتابخانه
                    همراه
                  </li>
                  <li className="my-2 text-sm md:my-6 md:text-xl">
                    <span className="text-primary">»</span> همیشه همراه و در
                    دسترس
                  </li>
                </ul>
              </div>
            </div>

            <div className="w-40 md:w-52">
              <img src={kImage} alt="" />
            </div>
          </div>
        </div>
        <div id="promo" className="w-full mx-auto mt-12 lg:w-7/12">
          <DownloadLinks />
          <p className="mt-4 text-sm text-center text-red-500">
            .نسخه جدید اندروید و آیفون کتابخوان به زودی نشر خواهد شد
          </p>

          <p className="p-4 mx-4 my-6 text-sm leading-loose text-right bg-gray-200 rounded md:text-base ">
            <span className="text-green-700 ">اپلیکیشن کتابخوان</span> امکار
            مطالعه صدها کتاب صوتی و الکترونیک را از طریق موبایل،، تبلت و وبسایت
            فراهم میسازد. شما با استفاده از اپلیکیشن کتابخوان همیشه و همه جا به
            کتابخانه خود دسترسی دارید و می توانید به سادگی از هر فرصتی برای
            مطالعه استفاده کنید. در کتابخوان برای همه سلیقه‌ها از داستان و رمان
            تا روانشناسی و علمی و... کتاب‌هایی پیدا می‌شود.
          </p>

          <div
            className="flex flex-row gap-2 px-4 py-4 overflow-x-scroll lg:gap-4"
            style={{ direction: "rtl" }}
          >
            <img
              src="https://play-lh.googleusercontent.com/hlIApJGPj9VCMtImXBP-zRpchd5Q0hl-rl1qMcueWhvecFnd2NUntsXvbqgaWXOI=w720-h310-rw"
              className="rounded img-responsive h-80 lg:h-96 "
              alt=""
            />
            <img
              src="https://play-lh.googleusercontent.com/pU_qfmHWGjfQzpGTD2wjqFf27Ge14COia-t9OHND0Ohk03bTKOy6E-1cjj6kicqHxsw=w720-h310-rw"
              className="rounded img-responsive h-80 lg:h-96 "
              alt=""
            />
            <img
              src="https://play-lh.googleusercontent.com/0VJkApRc9KqP4W_Qx_tymhbcImVHR3X-DHmuuUM0_oa59o11U49EMXKlkwBCywclvQ=w720-h310-rw"
              className="rounded img-responsive h-80 lg:h-96 "
              alt=""
            />
            <img
              src="https://play-lh.googleusercontent.com/oeJkhDWuO1IRCWKdlCHuOzadhCQWI5CmKFMBKIB_UcSVfvbsB3c-wnl0W8GVPi22aRs=w720-h310-rw"
              className="rounded img-responsive h-80 lg:h-96"
              alt=""
            />
            <img
              src="https://play-lh.googleusercontent.com/LWpzcIgntSz0blQvqF6SaCqgwRu-14u0i48ugyafwSBdj_TLQvBySQKT3f6a1b22hEw=w720-h310-rw"
              className="rounded img-responsive h-80 lg:h-96 "
              alt=""
            />
            <img
              src="https://play-lh.googleusercontent.com/cydmyP1LFCupjEH4UC5jsUOYlpTFnZ17k5y8IN_BmNmnHGu19BEKKf59JviNwo7H4eM=w720-h310-rw"
              className="rounded img-responsive h-80 lg:h-96 "
              alt=""
            />
            <img
              src="https://play-lh.googleusercontent.com/bs7p-2eUaQP7qUn5bjSRMrro_xEUXf4ZZo6mLRYTy_9P-KbAgNXgMw6OHN0SM7xXqSg=w720-h310-rw"
              className="rounded img-responsive h-80 lg:h-96 "
              alt=""
            />
            <img
              src="https://play-lh.googleusercontent.com/iHrYomtquRZnRxHgOT_3g9C9hJD9DgvlwQIrxnurc8hfM3SqSD5KjfWq3dw6h27CWxM=w720-h310-rw"
              className="rounded img-responsive h-80 lg:h-96"
              alt=""
            />
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default Applications;

import React from 'react';
import logo from "../assets/img/logo.png"
import { Link } from 'react-router-dom';



class Navigation extends React.Component {

  render() {

    return (
      <div className="container relative flex items-center justify-center max-w-full p-4 mx-auto bg-white">

        <div className='flex items-center gap-2'>
          <Link to="/">
            <a className='flex items-center gap-2'>
              <img
                src={logo}
                alt="کتابخوان"
                className='my-auto '
                style={{ width: 70 }}
              />
              <h1 style={{ color: "#3b5f41", fontWeight: 800, fontSize: 28 }}>کتابخوان</h1>
            </a>
          </Link>
        </div>

      </div>
    );
  }
}


export default Navigation;
import { Link } from "react-router-dom";
import Logo from "../assets/img/logo.png";
import DownloadLinks from "./DownloadLinks";

const Footer = () => {
  return (
    <footer
      className="px-5 py-8 mx-auto mt-8 text-gray-600 bg-white border-t-2 border-primary"
    >
      <div className="flex flex-col w-10/12 mx-auto">
        <ul className="flex flex-wrap items-center justify-center text-base md:ml-auto md:mr-auto">
          <li className="mx-3 text-sm md:mx-5 md:text-base hover:text-gray-900">
            <Link to="/about-us">درباره ما</Link>
          </li>
          <li className="text-sm md:text-base hover:text-gray-900">
            <Link to="/contact-us">تماس با ما</Link>
          </li>
          <li className="mr-3 text-sm md:mr-5 md:text-base hover:text-gray-900">
            <Link to="/tos">شرایط استفاده</Link>
          </li>
          <li className="mr-3 text-sm md:mr-5 md:text-base hover:text-gray-900">
            <Link to="/privacy-policy">حریم خصوصی</Link>
          </li>
          <div className="flex mt-2 mr-4 md:mt-0">
            <a
              href="https://facebook.com/ketabkhaan_com"
              target="_blank"
              className="ml-4 text-gray-500 hover:text-indigo-500" rel="noreferrer"
            >
              <svg
                fill="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                className="w-6 h-6"
                viewBox="0 0 24 24"
              >
                <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path>
              </svg>
            </a>
            <a
              href="https://t.me/ketabkhaan_com"
              target="_blank"
              className="ml-4 text-gray-500 hover:text-indigo-500" rel="noreferrer"
            >
              <svg
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" x="0px" y="0px" width="100" height="100" viewBox="0 0 50 50">
                <path d="M46.137,6.552c-0.75-0.636-1.928-0.727-3.146-0.238l-0.002,0C41.708,6.828,6.728,21.832,5.304,22.445	c-0.259,0.09-2.521,0.934-2.288,2.814c0.208,1.695,2.026,2.397,2.248,2.478l8.893,3.045c0.59,1.964,2.765,9.21,3.246,10.758	c0.3,0.965,0.789,2.233,1.646,2.494c0.752,0.29,1.5,0.025,1.984-0.355l5.437-5.043l8.777,6.845l0.209,0.125	c0.596,0.264,1.167,0.396,1.712,0.396c0.421,0,0.825-0.079,1.211-0.237c1.315-0.54,1.841-1.793,1.896-1.935l6.556-34.077	C47.231,7.933,46.675,7.007,46.137,6.552z M22,32l-3,8l-3-10l23-17L22,32z"></path>
              </svg>
            </a>
            <a
              href="https://instagram.com/ketabkhaan_com"
              target="_blank"
              className="ml-4 text-gray-500 hover:text-orange" rel="noreferrer"
            >
              <svg
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                className="w-6 h-6"
                viewBox="0 0 24 24"
              >
                <rect width="20" height="20" x="2" y="2" rx="5" ry="5"></rect>
                <path d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01"></path>
              </svg>
            </a>
            <a
              href="https://linkedin.com/in/ketabkhaan"
              target="_blank"
              className="ml-4 text-gray-500 hover:text-blue-500" rel="noreferrer"
            >
              <svg
                fill="currentColor"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="0"
                className="w-6 h-6"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="none"
                  d="M16 8a6 6 0 016 6v7h-4v-7a2 2 0 00-2-2 2 2 0 00-2 2v7h-4v-7a6 6 0 016-6zM2 9h4v12H2z"
                ></path>
                <circle cx="4" cy="4" r="2" stroke="none"></circle>
              </svg>
            </a>
          </div>
        </ul>
        <DownloadLinks />
        <div
          className="flex flex-col items-center justify-center w-full gap-4 mx-auto mt-12 md:w-10/12 md:flex-row"
          id="footer-text"
        >
          <img src={Logo} alt="کتابخوان" className="w-40" />
          <p className="text-justify">
            کتابخوان مرجع دانلود کتاب الکترونیکی و دانلود کتاب صوتی است که
            امکان دسترسی به صدها کتاب، رمان، مجله و کتاب صوتی را از طریق موبایل تبلت و کمپیوتر برای شما فراهم می‌کند.
            شما با استفاده از کتابخوان همیشه و همه جا به کتاب‌ها و کتابخانه خود
            دسترسی دارید و می‌توانید به سادگی از هر فرصتی برای مطالعه استفاده
            کنید. در کتابخوان برای همه سلیقه‌ها از داستان، رمان و شعر تا
            روانشناسی، تاریخی، علمی، موفقیت و... کتاب‌هایی پیدا می شود. اپلیکیشن
            کتابخوان برای اندروید، IOS در دسترس است.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

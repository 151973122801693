import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";
import TOS from "./pages/TOS";
import Applications from "./pages/Applications"
import PrivacyPolicy from "./pages/PrivacyPolicy"


const App = () => {
  return (
    <>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/about-us" component={About} />
        <Route path="/contact-us" component={Contact} />
        <Route path="/tos" component={TOS} />
        <Route path="/privacy-policy" component={PrivacyPolicy} />
        <Route path="/apps" component={Applications} />
        <Redirect to="/" />
      </Switch>
    </>
  );
};


export default App;

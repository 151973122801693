import React from 'react';
import ReactDOM from 'react-dom';
import "./assets/tailwind.output.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";



ReactDOM.render(
      <BrowserRouter>
        <ScrollToTop>
        <App />
        </ScrollToTop>
      </BrowserRouter>,
  document.querySelector("#root")
);

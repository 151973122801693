import Navigation from "../components/Navigation";
import Footer from "../components/Footer";

function About() {
  return (
    <>
      <Navigation />
      <div className="container py-5 antialiased focus-within main-section">
        <h1 className="text-2xl font-black">
          درباره کتابخوان
        </h1>
        <p className="mt-6">
          در دنیای مدرن امروز نقش و نگارهای زیادی در کتاب و کتابخوانی پدیدار
          گشته و این عرصه دستخوش تغییرات زیادی شده است. یکی از این تغییرات مهم
          نشر الکترونیک می باشد که سبب تغییر در وضعیت کتابخوانی، انتشار و توزیع
          سریع، کاهش هزینه ها، حذف کاغذ و دسترسی آسان به کتاب ها شده است.
        </p>

        <p className="mt-12 font-semibold">اهداف کتابخوان</p>
        <ul className="mx-6 leading-7 list-disc">
          <li>ترویج فرهنگ کتابخوانی</li>
          <li>ارائه نرم افزار کتابخوان تحت پلتفورم های مختلف</li>
          <li>گسترش فرهنگ مطالعه کتاب‌های الکترونیکی</li>
          <li>گسترش تعداد مخاطبان و کاربران</li>
          <li>جلوگیری از قطع درختان!!</li>
        </ul>

      </div>
      <Footer />
    </>
  );
}

export default About;
